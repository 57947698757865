import * as TabsPrimitive from '@radix-ui/react-tabs';
import { startCase } from 'lodash';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { Image } from 'react-datocms';
import { useRecoilValue } from 'recoil';

import { Badge } from '@/components/badge';
import { Container } from '@/components/container';
import { Heading } from '@/components/heading';
import { Link } from '@/components/link';
import { AdvancedSearchModal } from '@/components/modules/advanced-search/modal';
import { ScheduleSearchBar } from '@/components/modules/schedules/search-bar';
import { SearchBar } from '@/components/modules/search/bar';
import { ShipmentFormIntermodal } from '@/components/modules/shipment/form/intermodal';
import { PortsSearchAutocomplete } from '@/components/ports-search-autocomplete';
import { Globe } from '@/components/ui/globe';
import { useNotifications } from '@/hooks/use-notifications';
import { useRecoilValueAfterMount } from '@/hooks/use-recoil-value-after-mount';
import {
  MODE_AIR,
  MODE_WATER,
  ROUTING_SEARCH_URL,
  SCHEDULE_SEARCH_URL,
  SEARCH_FORM_VARIANTS,
  TRACKING_URL,
} from '@/lib/constants';
import { featuresState } from '@/state/features';
import { routeSearchDestinationState, routeSearchOriginState } from '@/state/routes';
import { advancedSearchFormOpenState, cargoSearchParamsState, searchParamsSelectorState } from '@/state/search';
import { StandaloneScheduleQueryParams } from '@/types';
import { OmniSearchBlockProps } from '@/types/block-types';
import { createPortInsightsDeepLinkPlaceUrl, createScheduleSearchPageUrl } from '@/utils/helpers/page-urls';
import { searchStateToQueryParams } from '@/utils/route';
import { tv } from '@/utils/styles';

import { Links } from './links';

const TABS = {
  ROUTING: 'route-planning',
  SCHEDULES: 'schedules',
  PORT_INSIGHTS: 'port-insights',
  TRACKING: 'tracking',
};

const OmniSearchBlock = ({ id = 'omni-search-block', image, showGlobe = true, className }: OmniSearchBlockProps) => {
  const { data: session } = useSession();
  const router = useRouter();
  const origin = useRecoilValue(routeSearchOriginState);
  const destination = useRecoilValue(routeSearchDestinationState);
  const searchParams = useRecoilValue(searchParamsSelectorState);
  const cargoParams = useRecoilValue(cargoSearchParamsState);
  const advancedSearchIsOpen = useRecoilValue(advancedSearchFormOpenState);
  const features = useRecoilValueAfterMount(featuresState, {});

  const userHasSubscription = !!session?.subscription?.orgId; // @TODO temporary check until we get the proper Tracking form working
  const SHOW_TRACKING = features?.omniSearchTracking;

  const { addNotification } = useNotifications();

  const handleRouteSearch = () => {
    const query = searchStateToQueryParams({ origin, destination, advancedSearchParams: searchParams, cargoParams });
    router.push({ pathname: ROUTING_SEARCH_URL, query });
  };

  const handleScheduleSearch = (query: StandaloneScheduleQueryParams) => {
    router.push({ pathname: SCHEDULE_SEARCH_URL, query }, undefined, {
      shallow: true,
    });
  };

  const handleSubmitShipment = async () => {
    addNotification({
      heading: 'You filled in the form',
      description: "This form isn't functional yet",
      duration: 5000,
      variant: 'success',
      onConfirm: () => router.push(TRACKING_URL),
      confirmButtonText: 'View shipments',
    });
  };

  const { base, container, backgroundImage, tabsList, tabsTrigger, tabsContent, headingStyles, globe } = styles();

  return (
    <section className={base({ className })} id={id}>
      <TabsPrimitive.Root defaultValue={`omni-search-tab-${TABS.ROUTING}`}>
        <Container className={container({ className: 'max-md:px-0' })} variant="slim">
          <div className="no-scrollbar overflow-x-auto max-md:pl-4">
            <TabsPrimitive.List className={tabsList()}>
              {Object.values(TABS).map((tab) => {
                if (tab === TABS.TRACKING && !SHOW_TRACKING) {
                  return null;
                }

                return (
                  <TabsPrimitive.Trigger
                    key={tab}
                    value={`omni-search-tab-${tab}`}
                    className={tabsTrigger()}
                    data-cy={`omni-search-tab-trigger-${tab}`}
                  >
                    {startCase(tab)}
                  </TabsPrimitive.Trigger>
                );
              })}
              <Link
                href={userHasSubscription ? TRACKING_URL : '/features/shipment-tracking'}
                className={tabsTrigger({ className: 'flex items-center' })}
              >
                Shipment Tracking{' '}
                <Badge variant="alert" className="ml-1 h-4">
                  New!
                </Badge>
              </Link>
            </TabsPrimitive.List>
          </div>
        </Container>
        <Container className={container()} variant="slim">
          <TabsPrimitive.Content
            value={`omni-search-tab-${TABS.ROUTING}`}
            data-cy={`omni-search-tab-content-${TABS.ROUTING}`}
            className={tabsContent()}
          >
            <Heading variant="h3" as="h2" className={headingStyles()}>
              Plan your next shipment route with all the available information
            </Heading>
            <SearchBar onSearch={handleRouteSearch} searchVariant="homepage" buttonLabel="Search" />
            <Links
              heading="Popular"
              links={[
                {
                  href: '/search?origin=Tokyo%2C+Japan&destination=Los+Angeles%2C+CA%2C+USA',
                  text: 'Tokyo to Los Angeles',
                },
                {
                  href: '/search?origin=Sydney+NSW%2C+Australia&destination=Shanghai%2C+China',
                  text: 'Sydney to Shanghai',
                },
                { href: '/search?origin=Oakland%2C+CA%2C+USA&destination=Taipei%2C+Taiwan', text: 'Oakland to Taipei' },
                {
                  href: '/search?origin=Hong+Kong&destination=Brisbane+QLD%2C+Australia',
                  text: 'Hong Kong to Brisbane',
                },
              ]}
            />
          </TabsPrimitive.Content>
          <TabsPrimitive.Content
            value={`omni-search-tab-${TABS.SCHEDULES}`}
            data-cy={`omni-search-tab-content-${TABS.SCHEDULES}`}
            className={tabsContent()}
          >
            <Heading variant="h3" as="h2" className={headingStyles()}>
              Reliable and accurate schedules for every carrier
            </Heading>
            <ScheduleSearchBar
              onSearch={handleScheduleSearch}
              hideFiltersButton
              isEnabled
              searchVariant={SEARCH_FORM_VARIANTS.HOMEPAGE}
              className="border-b-0 bg-transparent p-0 [&_label]:text-white"
              canToggleSearchBar={false}
            />
            <Links
              heading="Popular"
              links={[
                {
                  href: createScheduleSearchPageUrl({
                    origin: 'locode NLRTM',
                    destination: 'locode USNYC',
                    mode: MODE_WATER,
                  }),
                  text: 'NLRTM → USNYC',
                },
                {
                  href: createScheduleSearchPageUrl({
                    origin: 'locode CNSHG',
                    destination: 'locode USLAX',
                    mode: MODE_WATER,
                  }),
                  text: 'CNSHG → USLAX',
                },
                {
                  href: createScheduleSearchPageUrl({
                    origin: 'iata LHR',
                    destination: 'iata JFK',
                    mode: MODE_AIR,
                  }),
                  text: 'LHR → JFK',
                },
                {
                  href: createScheduleSearchPageUrl({
                    origin: 'iata DXB',
                    destination: 'iata CDG',
                    mode: MODE_AIR,
                  }),
                  text: 'DXB → CDG',
                },
              ]}
            />
          </TabsPrimitive.Content>
          <TabsPrimitive.Content
            value={`omni-search-tab-${TABS.PORT_INSIGHTS}`}
            data-cy={`omni-search-tab-content-${TABS.PORT_INSIGHTS}`}
            className={tabsContent()}
          >
            <Heading variant="h3" as="h2" className={headingStyles()}>
              Quickly find the nearest airport or seaport for your origin or destination
            </Heading>
            <PortsSearchAutocomplete />
            <Links
              heading="Popular"
              links={[
                { href: createPortInsightsDeepLinkPlaceUrl('Los Angeles, Ca, USA'), text: 'Los Angeles' },
                { href: createPortInsightsDeepLinkPlaceUrl('Singapore'), text: 'Singapore' },
                { href: createPortInsightsDeepLinkPlaceUrl('Shanghai, China'), text: 'Shanghai' },
                { href: createPortInsightsDeepLinkPlaceUrl('Rotterdam, Netherlands'), text: 'Rotterdam' },
              ]}
            />
          </TabsPrimitive.Content>
          {SHOW_TRACKING && (
            <TabsPrimitive.Content
              value={`omni-search-tab-${TABS.TRACKING}`}
              data-cy={`omni-search-tab-content-${TABS.TRACKING}`}
              className={tabsContent()}
            >
              <Heading variant="h3" as="h2" className={headingStyles()}>
                Enter your tracking number to quickly find your shipment
              </Heading>
              <ShipmentFormIntermodal
                onSubmit={handleSubmitShipment}
                buttonText="Search"
                className="[&_label]:text-white"
                disableButtonUntilValid
                buttonIcon="search"
              />
            </TabsPrimitive.Content>
          )}
        </Container>
      </TabsPrimitive.Root>

      {image?.responsiveImage && (
        <div className={backgroundImage()}>
          <Image data={image?.responsiveImage} layout="fill" />
        </div>
      )}
      {advancedSearchIsOpen && <AdvancedSearchModal onSearch={handleRouteSearch} />}
      {Boolean(showGlobe) && (
        <div className={backgroundImage()}>
          <Globe className={globe()} />
        </div>
      )}
    </section>
  );
};

const styles = tv({
  slots: {
    base: 'relative bg-blue-800 pb-12 pt-24 md:pb-36 md:pt-40 lg:pb-64 lg:pt-72',
    container: 'relative z-2',
    backgroundImage:
      'absolute bottom-0 left-1/2 top-0 z-1 w-full max-w-[1800px] -translate-x-1/2 overflow-hidden [&_img]:object-cover',
    tabsList: 'mb-5 flex gap-x-2 whitespace-nowrap outline',
    tabsContent: null,
    tabsTrigger:
      'flex h-8 items-center gap-1 whitespace-nowrap rounded-xl border border-solid px-3 py-1 text-baseSm leading-none text-white transition-colors hover:bg-white hover:text-blue-800 aria-selected:bg-white aria-selected:text-blue-800',
    headingStyles: 'text-white',
    globe: 'top-72',
  },
});

export { OmniSearchBlock };
