import { isEqual } from 'lodash';
import { createParser } from 'nuqs';

import { ShipmentCollectionFilter } from '@/hooks/use-shipment-collection';
import { ShipmentModel } from '@/lib/models/shipment/types';

export const parseAsCollectionFilter = createParser<ShipmentCollectionFilter | null>({
  parse(value) {
    if (!value) return null;

    const [k, v] = value.split('|');
    return { type: k as keyof ShipmentModel, value: v };
  },

  serialize(value) {
    if (!value) return '';
    return `${value.type}|${value.value}`;
  },

  eq: isEqual,
});
